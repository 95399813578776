import { useEffect, useState } from "react"
import NavBarMb from "src/layout/Header/NavBarMb"

import CheckboxPost from "src/components/CheckboxPost"
import upload from "@assets/images/brand_detail/upload.png"
import close from "@assets/images/brand_detail/close.png"

import { Spin, Upload, UploadFile, UploadProps } from "antd"
import { uploadSingleFile } from "src/api/uploadSingleFile"
import { createPost, editPost } from "src/api/communityApi"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAuth } from "src/customHook/useAuth"
import TextareaAutosize from "react-textarea-autosize"
import { checkNormalMembership } from "src/api/brandApi"

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

function PostPageMobile() {
  const [fileList, setFileList] = useState<any[]>([])
  const [previewImage, setPreviewImage] = useState<object[]>([])
  const [content, setContent] = useState<string>()
  const [onlyMembership, setOnlyMembership] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { state } = useLocation()
  const navigate = useNavigate()
  const { user }: any = useAuth()

  const params = useParams()
  const { brandName } = params

  const [dataCheckNormalMembership, setDataCheckNormalMembership] =
    useState(false)

  useEffect(() => {
    ;(async function getDataCheckNormalMembership() {
      const { data } = await checkNormalMembership({
        brandName: brandName,
      })
      if (data) {
        setDataCheckNormalMembership(data)
      }
    })()
  }, [])

  //edit
  useEffect(() => {
    if (state) {
      setContent(state?.content)
      setOnlyMembership(state?.onlyMembership)

      const dtMedias = state?.media && JSON.parse(state.media)

      const dtFileList: any = []
      if (dtMedias?.length > 0) {
        for (let i = 0; i < dtMedias.length; i++) {
          dtFileList.push({
            idPreview: Date.now(),
            src: dtMedias[i].url,
            isEdit: true,
          })
        }
      }

      setFileList(dtFileList)
      setPreviewImage(dtFileList)
    }
  }, [state])

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    return file.url || (file.preview as string)
  }

  const onChangeAntd: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (previewImage.length < 10) {
      const dtPreviewImage: any = previewImage.concat(newFileList).slice(0, 10)
      const dtFileList: any = fileList.concat(newFileList).slice(0, 10)

      const dataPreview = []
      for (let i = 0; i < dtPreviewImage.length; i++) {
        if (dtPreviewImage[i].idPreview) {
          dataPreview.push(dtPreviewImage[i])
        } else {
          const itemPreview = await handlePreview(dtPreviewImage[i])
          dataPreview.push({
            idPreview: Date.now(),
            src: itemPreview,
          })
        }
      }

      setFileList(dtFileList)
      setPreviewImage(dataPreview)
    }
  }

  const handleSubmit = async () => {
    if (content || fileList.length > 0) {
      setIsLoading(true)
      try {
        const dataMedias = []
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i]?.isEdit) {
            dataMedias.push({
              mediaType: "IMAGE",
              url: fileList[i].src,
            })
          } else {
            const dtFileList: any = fileList[i].originFileObj
            const formData = new FormData()
            formData.append("file", dtFileList)
            const respUpload: any = await uploadSingleFile(formData)
            console.log("respUpload", respUpload)
            if (respUpload) {
              dataMedias.push({
                // mediaType: respUpload.mimeType,
                mediaType: "IMAGE",
                url: respUpload.imageUrl,
              })
            }
          }
        }
        const resp: any = state?.postId
          ? await editPost({
              content: content ?? " ",
              onlyMembership: onlyMembership,
              id: state.postId,
              medias: dataMedias,
            })
          : await createPost({
              content: content ?? " ",
              onlyMembership: onlyMembership,
              targetBrandId: Number(state?.id),
              medias: dataMedias,
            })
        if (resp.result) {
          setIsLoading(false)
          navigate(-1)
        }
      } catch (error) {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <NavBarMb pageName={"포스트 작성"} />
      <div className="flex h-[calc(100vh_-_60px)] overflow-hidden fixed inset-0 z-[1001] top-[60px]">
        <div className="flex flex-col justify-between overflow-y-auto absolute inset-0 bg-[#fff] z-10 top-[24px] px-[24px]">
          <div className="flex-1 overflow-y-auto">
            <TextareaAutosize
              className="w-full focus:outline-none placeholder:text-[#A3A3A3] resize-none"
              placeholder="자유롭게 포스트를 남겨보세요.
상대방을 불쾌하게 하는 욕설이나 비하 표현은 삼가주세요."
              onChange={(e) => setContent(e.target.value)}
              value={content}
              maxLength={2000}
            />
            {/* <textarea
              rows={5}
              className="w-full focus:outline-none placeholder:text-[#A3A3A3]"
              onChange={(e) => {
                setContent(e.target.value)
              }}
              maxLength={2000}
              style={{ resize: "none" }}
              placeholder="자유롭게 포스트를 남겨보세요.
상대방을 불쾌하게 하는 욕설이나 비하 표현은 삼가주세요."
              value={content}
            /> */}
          </div>

          <div className="border-0 border-t border-solid border-t-[#BDBDBD]">
            <div className="text-right mt-[10px] mb-[40px] text-[#A3A3A3] text-[14px]">
              {content?.length ?? 0}/2000
            </div>

            <div className="pb-[30px]">
              <div className="flex justify-between items-center">
                <div>
                  <div className="cursor-pointer">
                    <Upload
                      fileList={[]}
                      customRequest={dummyRequest}
                      onChange={onChangeAntd}
                      multiple
                      maxCount={10}
                      showUploadList={false}
                      accept="image/*"
                    >
                      <img src={upload} alt="icon" />
                    </Upload>
                  </div>
                </div>
                <div className="flex gap-[18px] items-center">
                  <div>
                    {dataCheckNormalMembership && (
                      <CheckboxPost
                        label={
                          <div className="text-[14px] xs:ml-auto ml-[-5px]">
                            멤버십 전용
                          </div>
                        }
                        onChangeValue={(e) => setOnlyMembership(e)}
                      />
                    )}
                  </div>
                  <Spin spinning={isLoading}>
                    <div
                      className="font-pretendard700 text-[#fff] h-[44px] w-[163px] xs:w-[99px] leading-[44px] text-center bg-[#000] cursor-pointer"
                      onClick={handleSubmit}
                    >
                      작성하기
                    </div>
                  </Spin>
                </div>
              </div>

              <div className="flex gap-[9px] flex-wrap mt-[20px]">
                {previewImage.length > 0 &&
                  previewImage.map((dt: any, index) => (
                    <div
                      className="relative w-[calc(25%_-_6.75px)]"
                      onClick={() => {
                        const dtPreview = [...previewImage]
                        const result = dtPreview.splice(index, 1)
                        setPreviewImage(dtPreview)

                        const dtFileList = [...fileList]
                        const resultFileList = dtFileList.splice(index, 1)
                        setFileList(dtFileList)
                      }}
                      key={index}
                    >
                      <div className="absolute right-[5px] top-[5px] rounded-[50%] bg-[#fff] p-[4px] cursor-pointer">
                        <img src={close} alt="close" />
                      </div>
                      <img
                        src={dt.src}
                        alt="img upload"
                        className="aspect-square object-cover"
                      />
                    </div>
                  ))}
                {previewImage.length > 0 && (
                  <div className="text-[#A3A3A3] text-[13px] w-[25%] mt-auto">
                    ({previewImage.length}/10)
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostPageMobile
