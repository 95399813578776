import { useNavigate, useParams } from "react-router-dom"
import { MoneyConvert } from "src/util/format"
import Button from "../../Button"
import { useTranslation } from "react-i18next"
import CommonModal from ".."
import moment from "moment"
import { UrlInternal } from "src/constant/endpointRoute"
type Props = {}

function TransactionShopSuccess({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const status = urlParams.get("status")
  const type = urlParams.get("type")
  const orderId = urlParams.get("orderId")
  const params = useParams()
  const { id } = params

  const handleClose = () => {
    // navigate(`/my-page/order-detail/${id}?type=order`)
    if (type === "VOUCHER") {
      navigate(UrlInternal.ORDER_VOUCHER_DETAIL + `/${orderId}`)
    }
    if (type === "E_CONTENT") {
      navigate(UrlInternal.ORDER_ECONTENT_DETAIL + `/${orderId}`)
    }
    if (type === "PRODUCT") {
      navigate(UrlInternal.ORDER_PRODUCT_DETAIL + `/${orderId}`)
    }
  }

  return (
    <CommonModal isOpen={status === "SUCCESS"} handleClose={handleClose}>
      <div className="w-full h-full bg-primaryWhite">
        <p className="text-primary text-[20px] font-pretendard600 leading-normal mb-1">
          {t("Congratulation")}!
        </p>

        {type === "E_CONTENT" ? (
          <p className="text-gray1 text-[14px] sm:font-pretendard400 font-pretendard400 capitalize mt-[10px]">
            {t("Your e-content has been saved at my page")}
          </p>
        ) : (
          <p className="text-primary text-[16px]  leading-none">
            {t("You made payment successfully")}
          </p>
        )}
        <div className="flex justify-between gap-4 sm:mt-[30px] mt-[30px]">
          <Button theme="main" onClick={handleClose}>
            {type === "E_CONTENT"
              ? t("View my e-content")
              : t("View order detail")}
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default TransactionShopSuccess
