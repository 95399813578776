import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import CommonModal from "../Modal"

import OpenAppImg from "@assets/images/popup/open_app.jpg"
import Button from "../Button"

const PopupAdmin = () => {
  const [visible, setVisible] = useState<boolean>(true)
  const oldCookies: any = Cookies.get("POPUP")
  const pathname = window.location.pathname

  console.log("pathname", pathname)

  useEffect(() => {
    if (oldCookies || pathname !== "/") {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }, [])

  const handleCloseOneDay = () => {
    Cookies.set("POPUP", `HIDDEN`, {
      expires: 1,
    })
    setVisible(false)
  }

  return (
    visible && (
      <CommonModal
        isOpen={visible}
        width={"100%"}
        className="max-w-[fit-content] popup-open-app"
      >
        <img
          src={OpenAppImg}
          alt="img"
          className="cursor-pointer"
          onClick={() => window.open("https://moafriends.com/moafriends")}
        />

        <div className="flex gap-[10px] mx-[20px]  py-[20px]">
          <Button
            theme="main"
            variant="outline"
            onClick={() => handleCloseOneDay()}
          >
            24시간 동안 다시 열람하지 않습니다
          </Button>
          <Button theme="main" onClick={() => setVisible(false)}>
            닫기
          </Button>
        </div>
      </CommonModal>
    )
  )
}

export default PopupAdmin
