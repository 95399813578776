import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { getBalanceApi, getNormalMembership } from "src/api/myBalance"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import Button from "src/components/Button"
import MyPageCard from "src/components/Card/MyPageCard"
import NoData from "src/components/NoData"
import Pagination from "src/components/Pagination"
import useIsMobile from "src/customHook/useIsMobile"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import NavBarMb from "src/layout/Header/NavBarMb"

function MyBrandPage() {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile(1023)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const dataUser = useAppSelector(userDataSelect)
  const [balanceSumary, setBalanceSumary] = useState<any>([])
  const [pageable, setPageable] = useState<any>({})
  const [currentCount, setCurrentCount] = useState<string>("0")
  const [usedCount, setUsedCount] = useState<string>("0")
  const totalVip = pageable?.totalElement || 0
  const displayName = dataUser?.displayName
  const { state } = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const type = searchParams.get("type")
  const [active, setActive] = useState<any>(
    state?.active ?? type === "normal" ? 1 : 0,
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  const [query, setQuery] = useState<{
    page: number
    size: number
  }>({
    page: 1,
    size: 8,
  })

  const handleGetBalanceSumary = async (used: number) => {
    setIsLoading(true)
    try {
      if (used === 1) {
        // params = {
        //   ...params,
        //   used: 1,
        // }
        const { data } = await getNormalMembership()
        if (data) {
          const balance = data?.datas
          setBalanceSumary(balance?.membership)
          setPageable(data?.pageable)
          setCurrentCount(balance?.currentCount)
          setUsedCount(balance?.usedCount)

          setIsLoading(false)
        } else {
          setBalanceSumary([])
          setPageable([])
          setIsLoading(false)
        }
      } else {
        let params: any = {
          page: query?.page,
          size: query?.size,
        }
        const { data } = await getBalanceApi(params)

        if (data?.balance?.datas?.length > 0) {
          const balance = data?.balance?.datas
          setBalanceSumary(balance)
          setPageable(data?.balance?.pageable)
          setCurrentCount(data?.currentCount)
          setUsedCount(data?.usedCount)
          setIsLoading(false)
        } else {
          setBalanceSumary([])
          setPageable([])
          setIsLoading(false)
        }
      }
    } catch (error) {
      setBalanceSumary([])
      setPageable([])
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetBalanceSumary(active)
  }, [active, query])

  return (
    <>
      <div className="lg:pt-[60px] relative">
        {isMobile && (
          <div className="relative">
            <NavBarMb
              pageName="보유 멤버십"
              csGoback={() => navigate("/my-page")}
            />
          </div>
        )}

        <div className="z-10 lg:mt-0 relative">
          <div className="xs:container">
            {!isMobile && (
              <div className="font-pretendard700 text-[24px] leading-[24px] pb-[24px] border-b-[2px] border-b-primary">
                {t("보유 멤버십")}
              </div>
            )}
            <div className="flex mt-[12px] md:mt-[40px]">
              <div className="flex-1">
                <div className="flex items-center gap-[16px] relative z-20 justify-center lg:justify-start border-b border-b-solid md:border-none">
                  <Button
                    className={`lg:text-[18px] text-[14px] text-primary flex-1 md:flex-none ${
                      active === 0
                        ? "font-pretendard700 border-b-solid md:border-none border-b-2 border-primary"
                        : ""
                    } pb-[13px]`}
                    onClick={() => {
                      setActive(0)
                      setSearchParams((params) => {
                        params.set("type", "premium")
                        return params
                      })
                    }}
                  >
                    {t("프리미엄")}{" "}
                    <span className="hidden sm:inline pl-[3px]">
                      {currentCount}
                    </span>
                  </Button>
                  <span className="hidden md:block text-[18px] font-pretendard100 text-gray5">
                    |
                  </span>
                  <Button
                    className={`lg:text-[18px] text-[14px] text-primary flex-1 md:flex-none ${
                      active === 1
                        ? "font-pretendard700 border-b-solid md:border-none border-b-2 border-primary"
                        : ""
                    } pb-[13px]`}
                    onClick={() => {
                      setActive(1)
                      setSearchParams((params) => {
                        params.set("type", "normal")
                        return params
                      })
                    }}
                  >
                    {t("일반")}{" "}
                    <span className="hidden sm:inline pl-[3px]">
                      {usedCount}
                    </span>
                  </Button>
                </div>
                {/* {active === 0 && (
                  <p className="text-[16px] text-primary mt-[28px] px-[24px] xs:px-0">
                    <Trans
                      i18nKey="동욱님은 현재, N개 브랜드의 VIP 회원입니다."
                      values={{ displayName, totalVip }}
                    >
                      {displayName}님은 현재,
                      <span className="font-pretendard700">
                        {totalVip}개 브랜드
                      </span>
                      의 <span className="font-pretendard700">VIP</span>{" "}
                      멤버입니다.
                    </Trans>
                  </p>
                )} */}
                <div className="lg:gap-[20px] gap-[16px]">
                  {isLoading ? (
                    <div className="my-[150px] text-center text-[18px]">
                      멤버십 조회중입니다...
                    </div>
                  ) : (
                    <>
                      {balanceSumary?.length > 0 ? (
                        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-[20px] gap-y-[16px] md:gap-y-[20px] lg:mt-0 pt-[28px] md:pt-[40px] px-[16px] xs:px-0">
                          {balanceSumary &&
                            balanceSumary?.map((item: any, index: number) => {
                              return (
                                <MyPageCard
                                  key={index}
                                  item={item}
                                  activeTab={active}
                                />
                              )
                            })}
                        </div>
                      ) : (
                        <div className="my-[150px] text-center text-[18px]">
                          {active === 0
                            ? "현재 보유한 프리미엄 멤버십이 없습니다."
                            : "현재 보유한 일반 멤버십이 없습니다."}
                        </div>
                      )}
                    </>
                  )}
                  {totalVip > 8 && (
                    <div className="mt-[32px]">
                      <Pagination
                        showSizeChanger={false}
                        total={totalVip}
                        pageSize={query?.size}
                        current={Number(query.page) || 1}
                        onChange={(page) => {
                          setQuery((prev) => ({ ...prev, page }))
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default MyBrandPage
