import QrImage from "@assets/images/my_voucher/qr_default.png"
import moment from "moment"
import { formatPrice } from "src/util/formatPrice"

interface Props {
  onClickQr: (img: string) => void
  data: any
}

function CardVoucher({ onClickQr, data }: Props) {
  const date = moment(data?.expiredTime)
    .subtract(1, "minutes")
    .diff(moment(), "days")

  return (
    <div className="flex md:px-[21px] px-[12px] border-[#D6D5D5] border flex-1 leading-none">
      <div className="md:pt-[26px] md:pb-[34px] md:pr-[18px] pt-[13px] pb-[14px] pr-[11px] border-r border-[#D6D5D5] border-dashed relative">
        <div className="relative">
          <img
            src={data?.image}
            alt="img"
            className="object-cover md:max-w-[150px] md:max-h-[150px] max-w-[88px] max-h-[88px] aspect-square"
          />

          <img
            src={QrImage}
            alt="qr"
            className="absolute bottom-0 left-0 cursor-pointer md:max-w-[32px] max-w-[30px]"
            onClick={() => onClickQr(data?.qrUrl)}
          />
        </div>

        <div className="md:w-[21px] w-[12px] md:h-[10.5px] h-[5.5px] rounded-b-full border-[#D6D5D5] border border-t-0 bg-[#fff] absolute md:right-[-10.5px] right-[-5.5px] top-[-1px] z-10" />
        <div className="md:w-[21px] w-[12px] md:h-[10.5px] h-[5.5px] rounded-t-full border-[#D6D5D5] border border-b-0 bg-[#fff] absolute md:right-[-10.5px] right-[-5.5px] bottom-[-1px] z-10" />
      </div>

      <div className="md:px-[24px] md:py-[9px] px-[13px] pt-[5px] pb-[20px] pr-0 flex-1 relative">
        <div className="flex justify-end gap-[8px] items-center mb-[8px] md:mr-[-45px] mr-[-12px]">
          <div className="text-[#666666] md:text-[13px] text-[12px]">
            {data?.code}
          </div>
          <div className=" bg-[#E50646] text-center md:px-[8px] px-[5px] md:py-[6.5px] py-[2px] md:text-[12px] text-[11px] font-pretendard600 text-[#fff]">
            {date > 0 ? `D-${date}` : "오늘까지"}
          </div>
        </div>

        <div className="text-[#666666] md:text-[14px] text-[12px]">
          {data?.brandName}
        </div>
        <div className="md:mt-[11px] md:mb-[9px] mt-[5px] mb-[6px]">
          <span className="font-pretendard700 md:text-[28px] text-[15px]">
            {formatPrice(data?.amount)}원
          </span>
          <span className="text-[#666666] md:text-[28px] text-[15px]">
            / {formatPrice(data?.rootAmount)}원
          </span>
        </div>
        {/* <div className="flex ">
          <div
            className={`md:py-[4.5px] py-[2px] px-[8px] text-center text-[#fff] md:text-[12px] text-[11px]  ${
              date > 10 ? "bg-[#908F90]" : "bg-[#FF31A0]"
            }`}
          >
            D-{date}
          </div>
          <div className="md:py-[4.5px] py-[2px] px-[8px] bg-[#000000] text-center text-[#fff] md:text-[12px] text-[11px] ">
            {data?.code}
          </div>
        </div> */}
        <div className="text-[#666] md:text-[14px] text-[12px] md:mt-[28px] md:mb-[6px] mt-[12px] mb-[3px]">
          {moment(data?.dateOfUse).format("YYYY-MM-DD")} ~{" "}
          {moment(data?.expiredTime)
            .subtract(1, "minutes")
            .format("YYYY-MM-DD")}
        </div>
        <div className="md:text-[16px] text-[12px]">{data?.voucherName}</div>

        <div className="flex flex-col md:gap-[11px] gap-[6.5px] absolute md:right-[-22px] right-[-13px] top-[50%] translate-y-[-50%] z-10">
          {new Array(4).fill("").map((_, index) => (
            <div
              className="md:h-[22px] h-[12px] md:w-[14px] w-[7px] rounded-l-full border-[#D6D5D5] border border-r-0 bg-[#fff]  z-10"
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CardVoucher
