import ChatText from "@assets/images/brand_detail/ChatText.png"
import edit_square from "@assets/images/brand_detail/edit_square.png"
import MoreText from "@assets/images/brand_detail/more_vert_comment.png"
import new_release from "@assets/images/brand_detail/new_releases.png"
import shapeBt from "@assets/images/brand_detail/shadow-bt.png"
import { Spin } from "antd"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import ReactPlayer from "react-player"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { BottomSheet } from "react-spring-bottom-sheet"
import TextareaAutosize from "react-textarea-autosize"
import { getDetailFeed } from "src/api/brandApi"
import {
  commentListFeed,
  createCommentFeed,
  deleteCommentFeed,
  deletePost,
  editCommentFeedApi,
} from "src/api/communityApi"
import { useAppSelector } from "src/app/hooks"
import LikeFeed from "src/components/LikeFeed"
import ModalBase from "src/components/ModalBase"
import SelectFilterComment from "src/components/SelectFilterComment"
import VideoJS from "src/components/VideoJS"
import { UrlInternal } from "src/constant/endpointRoute"
import { useAuth } from "src/customHook/useAuth"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"
import NavBarMb from "src/layout/Header/NavBarMb"
import { formatPrice } from "src/util/formatPrice"
import { handleDateNewPost } from "src/util/handleDate"
import { useOutsideAlerter } from "src/util/hook/useOutSide"
import "./style.scss"

function DetailFeedMobile() {
  const dataBrandDetail = useAppSelector(brandDataDetailSelect)
  const { isLogined, user } = useAuth()
  const [dataComment, setDataComment] = useState<any[]>([])
  const [content, setContent] = useState<string>()
  const [pageable, setPageable] = useState<any>({})
  const { id, brandName } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>({})
  const { pathname, search, state } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const commentID = searchParams.get("commentID")
  const brandSiteRef: any = useRef<HTMLDivElement>(null)
  const [isModalReport, setIsModalReport] = useState({
    isOpen: false,
    id: null,
  })
  const [editComment, setEditComment] = useState<any>({
    isEdit: false,
    data: {},
    content: null,
  })
  const [isModalDeletePost, setIsModalDeletePost] = useState<boolean>(false)
  const [isActionMoreTextPost, setIsActionMoreTextPost] =
    useState<boolean>(false)
  const boxListRef = useRef(null)
  useOutsideAlerter(boxListRef, () => {
    setIsActionMoreTextPost(false)
    setIsModalReport({
      isOpen: false,
      id: null,
    })
  })
  const urlIncontentMetadata = data?.urlIncontentMetadata
    ? JSON.parse(data?.urlIncontentMetadata)
    : []
  const { i18n } = useTranslation()

  console.log("state", state)

  const optionsSortSelect = [
    {
      value: "latest",
      label: "Latest comment",
    },
    {
      value: "newest",
      label: "Oldest comment",
    },
  ]
  const [sortSelect, setSortSelect] = useState(optionsSortSelect[0].value)

  useEffect(() => {
    if (commentID) {
      brandSiteRef.current?.focus()
    }
  }, [commentID])

  const getDetailPost = async () => {
    try {
      const resp: any = await getDetailFeed({
        id: Number(id),
      })

      if (resp.result) {
        const dataResp = resp.data
        setData(dataResp)
        // if (!dataResp.content) {
        //   navigate({
        //     pathname: "/auth",
        //     search: `backUrl=${pathname}${search}`,
        //   })
        // } else {
        //   setData(dataResp)
        // }
      } else {
        console.log("resp", resp)
        if (resp.error.errorCode === "comment_003") {
          navigate(`/${brandName}`)
        }
      }
    } catch (error) {
      setData({})
    }
  }

  const getDataList = async (
    isLoadmore?: boolean,
    page?: number,
    size?: number,
    sortFilter?: string,
  ) => {
    try {
      const respBrandPushCommentId = []
      if (commentID) {
        const resp: any = await commentListFeed({
          page: page ?? 1,
          size: size ?? 12,
          feedId: Number(id),
          id: Number(commentID),
          sortFilter: sortFilter || sortSelect,
        })
        if (resp.result) {
          respBrandPushCommentId.push(resp.data.datas[0])
        }
      }

      const resp: any = await commentListFeed({
        page: page ?? 1,
        size: size ?? 12,
        feedId: Number(id),
        excludeId: commentID ? Number(commentID) : null,
        sortFilter: sortFilter || sortSelect,
      })
      if (resp.result) {
        const dataResp = [...respBrandPushCommentId, ...resp.data.datas]

        setDataComment(dataResp)
        setPageable(resp.data.pageable)

        if (isLoadmore) {
          setDataComment([...dataComment, ...dataResp])
        }
      } else {
        setDataComment([])
        setPageable({})
      }
    } catch (error) {
      setDataComment([])
      setPageable({})
    }
  }

  useEffect(() => {
    getDataList()
    getDetailPost()
  }, [])

  const handlePostComment = async () => {
    if (!isLogined) {
      navigate({
        pathname: "/auth",
        search: `backUrl=${pathname}?tab=community`,
      })
    } else {
      if (content) {
        try {
          const resp: any = await createCommentFeed({
            feedId: Number(id),
            content: content,
          })
          if (resp.result) {
            getDataList()
            setContent("")
            getDetailPost()
            brandSiteRef.current?.focus()

            // brandSiteRef.current?.scrollTo({
            //   top: brandSiteRef.current?.scrollHeight,
            //   behavior: "smooth", // Cuộn mượt mà
            // })
          }
        } catch (error) {}
      }
    }
  }

  const handleLoadMore = () => {
    setTimeout(() => {
      getDataList(true, pageable.page + 1)
    }, 1500)
  }

  const handleDeleteComment = async (commentID: number) => {
    try {
      const resp: any = await deleteCommentFeed({
        commentId: commentID,
      })
      if (resp.result) {
        getDataList(false, 1, pageable.page * 12)
        getDetailPost()
        // const dt = dataComment.filter((dt: any) => dt.commentId !== commentID)
        // setDataComment(dt)
      }
    } catch (error) {}
  }

  const handleSubmitEdit = async () => {
    if (editComment.content) {
      try {
        const resp: any = await editCommentFeedApi({
          commentId: editComment.data.commentId,
          content: editComment.content,
        })

        if (resp.result) {
          setEditComment({
            data: {},
            isEdit: false,
            content: null,
          })
          getDataList()
        }
      } catch (error) {}
    }
  }

  const renderActionComment = (
    isMinePost: boolean,
    isMineBrand: boolean,
    dt: any,
  ) => {
    if (isMinePost) {
      return (
        <>
          <div
            className="hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              setEditComment({
                isEdit: true,
                data: dt,
                content: dt?.content,
              })
            }}
          >
            수정하기
          </div>
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              handleDeleteComment(dt?.commentId)
            }}
          >
            삭제하기
          </div>
        </>
      )
    } else {
      if (!isMineBrand) {
        return (
          <div
            className={`hover:font-pretendard600 hover:underline text-[14px] ${
              dt?.reported && "text-[#C8C8C8] cursor-not-allowed"
            }`}
            onClick={() => {
              if (!dt?.reported) {
                navigate(
                  `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_REPORT}`,
                  {
                    state: {
                      ...dt,
                      isFeed: true,
                    },
                  },
                )
              }
            }}
          >
            신고하기
          </div>
        )
      } else {
        return (
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              handleDeleteComment(dt?.commentId)
            }}
          >
            삭제하기
          </div>
        )
      }
    }
  }

  const renderActionPost = (isMinePost: boolean, isMineBrand: boolean) => {
    if (isMinePost) {
      return (
        <>
          <div
            className="hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() =>
              navigate(
                `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_POST}`,
                {
                  state: data,
                },
              )
            }
          >
            수정하기
          </div>
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              setIsModalDeletePost(true)
            }}
          >
            삭제하기
          </div>
        </>
      )
    } else {
      if (!isMineBrand) {
        return (
          <div
            className={`hover:font-pretendard600 hover:underline text-[14px] ${
              data?.reported && "text-[#C8C8C8] cursor-not-allowed"
            }`}
            onClick={() => {
              if (!data?.reported) {
                navigate(
                  `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_REPORT}`,
                  {
                    state: { ...data, isFeed: true },
                  },
                )
              }
            }}
          >
            신고하기
          </div>
        )
      } else {
        return (
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              setIsModalDeletePost(true)
            }}
          >
            삭제하기
          </div>
        )
      }
    }
  }

  const handleDeletePost = async () => {
    try {
      const resp: any = await deletePost({
        id: data?.postId,
      })
      if (resp.result) {
        setIsModalDeletePost(false)

        navigate(`/${brandName}?tab=community`)
      }
    } catch (error) {
      setIsModalDeletePost(false)
    }
  }

  return (
    <>
      {/**delete post */}
      <ModalBase
        isVisible={isModalDeletePost}
        setCancel={() => setIsModalDeletePost(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[30px]">삭제하시겠습니까?</div>

        <div className="flex gap-[10px]">
          <div
            className="w-[50%] bg-[#fff] text-[#000] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer border border-solid border-[#000]"
            onClick={() => setIsModalDeletePost(false)}
          >
            취소
          </div>
          <div
            className="w-[50%] bg-[#000] text-[#fff] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
            onClick={handleDeletePost}
          >
            확인
          </div>
        </div>
      </ModalBase>

      {/**report me  mobile*/}
      {editComment.isEdit && (
        <div
          className="bg-bottomSheetOverlay fixed w-[100vw] h-[100vh] left-0 top-0 z-[1002]"
          onClick={() =>
            setEditComment({
              isEdit: false,
              data: {},
              content: null,
            })
          }
        ></div>
      )}
      <BottomSheet
        open={editComment.isEdit}
        onDismiss={() =>
          setEditComment({
            isEdit: false,
            data: {},
            content: null,
          })
        }
        snapPoints={({ minHeight }) => minHeight}
        className="bottom-sheet"
        blocking={false}
      >
        <div className="px-[24px] pt-[25px] pb-[40px]">
          <div className="flex gap-[8px] ">
            <img
              src={editComment?.data?.commentAvatar}
              alt="icon"
              className="w-[24px] h-[24px] aspect-square rounded-[50%]"
            />
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <div className="font-pretendard700 flex items-center">
                  {editComment?.data?.creatorName}{" "}
                  {editComment?.data?.userRole === "BRAND" && (
                    <img
                      src={new_release}
                      alt="logo"
                      className="inline-block pl-[8px]"
                    />
                  )}
                  {editComment?.data?.creatorIsMembershipOfTargetBrand && (
                    <div className="bg-[#FF6E6E] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600 ml-[8px]">
                      멤버십
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-[4px] pb-[5px] text-[12px] text-[#757575]">
                {handleDateNewPost(
                  editComment?.data?.updatedAt ?? editComment?.data?.createdAt,
                  i18n.language,
                )}
              </div>
            </div>
          </div>
          <TextareaAutosize
            className="bg-[#F5F5F5] py-[7px] px-[20px] w-full focus:outline-none text-[14px] placeholder:text-[#A3A3A3] max-w-[100%] resize-none flex-1"
            onChange={(e) => {
              setEditComment({
                ...editComment,
                content: e.target.value,
              })
            }}
            value={editComment.content}
            maxLength={2000}
          />

          <div className="flex gap-[10px] justify-end mt-[15px]">
            <div
              className="border border-solid border-[#D4D4D4] w-[100px] h-[44px] leading-[44px] font-pretendard700 text-center"
              onClick={() =>
                setEditComment({
                  isEdit: false,
                  data: {},
                  content: null,
                })
              }
            >
              취소
            </div>
            <div
              className="border border-solid border-[#D4D4D4] w-[100px] h-[44px] leading-[44px] font-pretendard700 text-[#fff] bg-[#000] text-center"
              onClick={handleSubmitEdit}
            >
              업로드
            </div>
          </div>
        </div>
      </BottomSheet>

      <NavBarMb
        pageName={"포스트"}
        // csGoback={() => {
        //   if (state?.currentPage === "/") {
        //     // navigate(-1)
        //     navigate(`/`, {
        //       state: {
        //         currentPage: state?.currentPage,
        //       },
        //     })
        //   } else {
        //     navigate(-1)
        //   }
        // }}
      />

      <div className="flex h-[calc(100vh_-_60px)] overflow-hidden fixed inset-0 z-[1001] top-[60px]">
        <div className="flex flex-col justify-between overflow-y-auto absolute inset-0 bg-[#fff] z-10 top-[24px]">
          <div className="flex-1 overflow-y-auto px-[24px]" id="scrollableDiv">
            <div className="flex justify-between">
              <div className="flex gap-[8px]">
                <img
                  src={data?.brandThumbnail}
                  // src={data?.thumbnail ?? data?.brandThumbnail}
                  alt="icon"
                  className="w-[48px] h-[48px] aspect-square rounded-[50%]"
                />
                <div>
                  <div className="font-pretendard700 flex items-center">
                    {data?.title}
                  </div>
                  <div className="pt-[8px] text-[12px] text-[#757575]">
                    {handleDateNewPost(
                      data?.updatedAt ?? data?.createdAt,
                      i18n.language,
                    )}
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer relative"
                onClick={() => setIsActionMoreTextPost((prev) => !prev)}
              >
                <img src={MoreText} alt="mor e text" />

                {isActionMoreTextPost && (
                  <div
                    className="absolute top-[30px] right-[0] shadow-custom3 bg-[#fff] w-[135px] p-[10px] text-left z-10"
                    ref={boxListRef}
                  >
                    <img
                      src={shapeBt}
                      alt="icon"
                      className="absolute right-[0] top-[-15px] z-[-10]"
                    />
                    <div className="absolute right-[5px] top-[-2px] bg-[#fff] w-[12px] h-[12px]"></div>
                    {renderActionPost(data?.isMine, dataBrandDetail?.isMine)}
                  </div>
                )}
              </div>
            </div>

            {data?.media && JSON.parse(data?.media)?.length > 0 && (
              <div className="flex flex-col gap-[24px] py-[16px]">
                {JSON.parse(data?.media)?.map((item: any, index: number) => (
                  <img
                    src={item?.url}
                    alt="img"
                    key={index}
                    className="max-w-[100%]"
                  />
                ))}
              </div>
            )}

            {data?.video?.url && (
              <div className="pt-[24px]">
                <VideoJS url={data?.video?.url} />
              </div>
            )}

            <div className="py-[24px]">
              {data?.onlyMemberShip && (
                <div className="bg-[#FF6E6E] mr-[5px] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600">
                  멤버십
                </div>
              )}

              <div
                className="content-html"
                dangerouslySetInnerHTML={{
                  __html: data?.content,
                }}
              ></div>
            </div>
            {urlIncontentMetadata.length > 0 && (
              <div className="border border-[#D4D4D4] mb-[24px]">
                <div className="py-[16px] px-[20px]">
                  <div className="text-[#767575] font-pretendard500 text-[12px]">
                    {urlIncontentMetadata[0]?.sitename}
                  </div>
                  <div className="text-[14px] font-pretendard600 line-clamp-1">
                    {urlIncontentMetadata[0]?.title}
                  </div>
                  <div className="line-clamp-2 text-[#767575] font-pretendard500 text-[12px] pt-[11px]">
                    {urlIncontentMetadata[0]?.description}
                  </div>
                </div>
                <ReactPlayer
                  url={urlIncontentMetadata[0]?.url}
                  style={{
                    maxWidth: "100%",
                    height: "100%",
                    maxHeight: "300px",
                  }}
                />
              </div>
            )}

            <div className="flex gap-[20px] pb-[24px] border-0 border-solid border-b border-b-[#E5E5E5]">
              <LikeFeed
                classLabel="sm:text-[16px] text-[14px] !font-pretendard400"
                classIcon="sm:max-h-[16px] max-h-[13.75px]"
                count={data?.cntLike}
                active={data?.doLike}
                id={data?.id}
              />
              <div
                className="flex sm:items-center gap-[5px] cursor-pointer"
                // onClick={() => setIsShowFullComment(true)}
              >
                <img
                  src={ChatText}
                  alt="ChatText"
                  className="sm:max-h-[26px] max-h-[21px]"
                />
                <div className="sm:text-[16px] text-[14px]">
                  {formatPrice(Number(data?.cntComment))}
                </div>
              </div>
            </div>

            <div className="max-w-[140px] ml-auto mt-[8px] mb-[15px]">
              <SelectFilterComment
                options={optionsSortSelect}
                value={sortSelect}
                onChange={(e) => {
                  setSortSelect(e)
                  getDataList(false, undefined, undefined, e)
                }}
              />
            </div>

            <InfiniteScroll
              dataLength={dataComment?.length}
              next={handleLoadMore}
              hasMore={pageable?.totalElement > dataComment?.length}
              loader={
                <div className="flex justify-center items-center py-[30px] overflow-hidden">
                  <Spin tip="Loading" size="small" />
                </div>
              }
              scrollableTarget="scrollableDiv"
              className={`relative ${
                dataComment?.length === 0 ? "mb-[100px]" : ""
              }`}
            >
              <input
                autoFocus={commentID ? true : false}
                ref={brandSiteRef}
                className="h-0 w-0 absolute left-0 right-0 top-0"
              />

              <div className="flex flex-col gap-[24px]">
                <div className="mt-[20px] flex flex-col gap-[20px]">
                  {dataComment &&
                    dataComment?.map((dt, index) => (
                      <div className="flex gap-[8px]" key={index}>
                        <img
                          src={dt?.commentAvatar}
                          alt="icon"
                          className="w-[24px] h-[24px] aspect-square rounded-[50%]"
                        />
                        <div className="flex-1">
                          <div className="flex items-center justify-between">
                            <div className="font-pretendard700 flex items-center">
                              {dt?.creatorName}{" "}
                              {dt?.userRole === "BRAND" && (
                                <img
                                  src={new_release}
                                  alt="logo"
                                  className="inline-block pl-[8px]"
                                />
                              )}
                              {dt?.creatorIsMembershipOfTargetBrand && (
                                <div className="bg-[#FF6E6E] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600 ml-[8px]">
                                  멤버십
                                </div>
                              )}
                            </div>
                            <div
                              className="cursor-pointer relative"
                              onClick={() =>
                                setIsModalReport({
                                  isOpen: !isModalReport.isOpen,
                                  id: dt?.commentId,
                                })
                              }
                            >
                              <img src={MoreText} alt="more text" />

                              {isModalReport.isOpen &&
                                isModalReport.id === dt?.commentId && (
                                  <div
                                    className="absolute top-[0] right-[30px] shadow-custom3 bg-[#fff] w-[135px] p-[10px] text-left z-10"
                                    ref={boxListRef}
                                  >
                                    <img
                                      src={shapeBt}
                                      alt="icon"
                                      className="absolute right-[-17px] top-[2px] rotate-90 z-[-10]"
                                    />
                                    <div className="absolute right-[-1px] top-[7px] bg-[#fff] w-[12px] h-[12px]"></div>
                                    {renderActionComment(
                                      dt?.isMine,
                                      dataBrandDetail?.isMine,
                                      dt,
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="pt-[4px] pb-[20px] text-[12px] text-[#757575]">
                            {handleDateNewPost(
                              dt?.updatedAt ?? dt?.createdAt,
                              i18n.language,
                            )}
                          </div>
                          <div
                            className="pb-[16px] whitespace-pre-line"
                            dangerouslySetInnerHTML={{
                              __html: dt?.content,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </InfiniteScroll>
          </div>

          <div
            className={`flex gap-[8px] items-center mb-[24px] mx-[24px] bg-[#F5F5F5] `}
            // className={`flex gap-[8px] items-center mb-[24px] mx-[24px] bg-[#F5F5F5] ${
            //   !isLogined && "pointer-events-none"
            // }`}
            onClick={() => {
              if (!isLogined) {
                navigate({
                  pathname: "/auth",
                  search: `backUrl=${pathname}${search}`,
                })
              }
            }}
          >
            <TextareaAutosize
              className="bg-[#F5F5F5] py-[14px] px-[20px] w-full focus:outline-none text-[14px] placeholder:text-[#A3A3A3] max-w-[100%] resize-none"
              placeholder="댓글을 입력하세요"
              onChange={(e) => setContent(e.target.value)}
              value={content}
            />
            <div className="pr-[13px]">
              <img
                src={edit_square}
                alt="edit_square"
                className="min-w-[23px] aspect-square h-[23px]"
                onClick={handlePostComment}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailFeedMobile
