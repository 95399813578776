import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
// import counterReducer from "../features/counter/counterSlice"
import searchReducer from "@features/Search/searchSlice"
import { combineReducers } from "redux"
import { PERSIST, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import confirmModalSlice from "src/features/confirmTransaction/confirmTransactionSlice"
import drawerSlice from "src/features/drawer/drawerSlice"
import headerSlice from "src/features/header/headerSlice"
import userSlice from "src/features/user/userSlice"
import userDataSlice from "src/features/userData/userDataSlice"
import brandDataSlice from "src/features/brandData/brandDataSlice"
import brandCategoriesSlice from "src/features/brandCategories/brandCategoriesSlice"
import loadingSlice from "src/features/loading/loadingSlice"
import dataBrandMappingSlice from "src/features/dataBrandMapping/dataBrandMappingSlice"
// import { PERSIST } from "@reduxjs/toolkit/constants/actionTypes";

const rootPersistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth"],
}

const rootReducer = combineReducers({
  // counter: counterReducer,
  search: searchReducer,
  confirmModal: confirmModalSlice,
  drawer: drawerSlice,
  header: headerSlice,
  user: persistReducer({ key: "user", storage }, userSlice),
  userData: userDataSlice,
  brandData: brandDataSlice,
  brandCategories: brandCategoriesSlice,
  loading: loadingSlice,
  brandMappingData: persistReducer(
    { key: "dataBrandMapping", storage },
    dataBrandMappingSlice,
  ),
})
const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const persistor = persistStore(store)
